import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import ENV from "@config/env";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { getBillingReportHistories } from "@services/reports";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import Label from "../Label";
import TextInput from "../TextInput";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";

const BillingReportsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
  }>({
    fromDate: new Date(
      currentAccountingPeriodCheck?.accountingPeriod?.periodStart
    ),
    toDate: new Date(currentAccountingPeriodCheck?.accountingPeriod?.periodEnd),
  });
  const tableFields = [
    { title: t("reporting.field_timestamp"), name: "timestamp" },
    { title: t("reporting.field_quarter"), name: "quarter" },
    { title: t("reporting.field_generated_by"), name: "generatedBy" },
    { title: t("common.action"), name: "action" },
  ];

  const { isLoading, refetch } = useQuery(
    ["billingReportHistories", filter],
    () =>
      getBillingReportHistories({
        fromDate: filter?.fromDate,
        toDate: filter?.toDate,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      enabled: !!filter?.fromDate && !!filter?.toDate,
      onSuccess: (response) => {
        setData(
          response?.map((record: any) => ({
            ...record,
            generatedBy: record.generatedBy?.name,
            quarter: Array.from(
              new Set([
                `Q${formatDate(new Date(record.fromDate), "q yyyy")}`,
                `Q${formatDate(new Date(record.toDate), "q yyyy")}`,
              ])
            ).join(" - "),
            timestamp: formatDateTime(new Date(record.createdAt)),
            action: (
              <div className="flex gap-2">
                <a
                  href={`${ENV.API_HOST}/evidences/${record.evidences?.[0]?.id}/download`}
                  target="_blank"
                  className="btn-default"
                  rel="noreferrer"
                >
                  {t("common.view")}
                </a>
              </div>
            ),
          }))
        );
      },
    }
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col p-4 grow">
        <Heading1 light>
          {t("accounting_period_checks.billing_reports")}{" "}
          <strong className="ml-6">{data?.length}</strong>
        </Heading1>
        <div className="flex flex-col grow gap-4">
          <form className="flex flex-wrap gap-3 justify-start">
            <div className="w-full sm:w-auto">
              <Label htmlFor="from-date">{t("reporting.filter_dates")}</Label>
              <div className="flex flex-col sm:flex-row gap-1">
                <div className="max-w-max">
                  <TextInput
                    type="date"
                    placeholder="From"
                    value={
                      filter.fromDate &&
                      formatDate(filter.fromDate, "yyyy-MM-dd")
                    }
                    onChange={(e) => {
                      handleFilterChange(
                        "fromDate",
                        new Date(`${e.target.value}T00:00:00`)
                      );
                    }}
                  />
                </div>
                <ArrowRightCalendarIcon className="w-10 h-10 hidden sm:block" />
                <div className="max-w-max">
                  <TextInput
                    type="date"
                    placeholder="To"
                    value={
                      filter.toDate && formatDate(filter.toDate, "yyyy-MM-dd")
                    }
                    min={
                      filter.fromDate &&
                      formatDate(filter.fromDate, "yyyy-MM-dd")
                    }
                    onChange={(e) => {
                      handleFilterChange(
                        "toDate",
                        new Date(`${e.target.value}T23:59:59`)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end sm:w-auto">
              <button
                type="button"
                className="btn-default"
                onClick={() => refetch()}
              >
                {t("common.refresh")}
              </button>
            </div>
            <div className="flex items-end w-full ml-auto sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1 w-auto"
                to="/polestar/reports/billing"
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("accounting_period_checks.add_billing_report")}
              </Link>
            </div>
          </form>
          <Table
            fields={tableFields}
            data={data}
            pageSize={100}
            loading={isLoading}
            stickyHeader
          />
        </div>
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          billingReportsCheckedAt: new Date(),
          billingReportsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default BillingReportsChecklist;
