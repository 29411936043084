import Heading from "@components/layout/Heading";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../TextInput";
import {
  BalanceAdjustmentType,
  useBalanceAdjustmentContext,
} from "@context/BalanceAdjustmentContext";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import Label from "../Label";
import DecimalInput from "../DecimalInput";
import { formatVolume } from "@utils/formatVolume";
import { convertLiterToML } from "@utils/convertUnits";
import { formatDatetimeInput } from "@utils/formatDate";
import { isDate } from "lodash";

type CreateBalanceAdjustmentEnterDataProps = {
  onSave: () => void;
  onGoBack: () => void;
};

const CreateBalanceAdjustmentEnterData: FunctionComponent<
  CreateBalanceAdjustmentEnterDataProps
> = ({ onSave, onGoBack }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();
  const level1ResourceId = balanceAdjustmentData.level1Resource?.id;

  const { data: currentAccountingPeriod } = useAllAccountingPeriods({
    options: {
      enabled: Boolean(level1ResourceId),
      select: (data: any) => data?.[0],
    },
    params: {
      level1ResourceId,
      isActive: true,
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave();
  };

  const calculateAdjustment = (
    beforeAdjustmentReading: number,
    startReading: number,
    endReading: number,
  ) => {
    if (startReading < endReading) {
      if (
        beforeAdjustmentReading > startReading &&
        beforeAdjustmentReading > endReading
      ) {
        return endReading - startReading;
      } else if (
        (beforeAdjustmentReading < startReading &&
          beforeAdjustmentReading < endReading) ||
        (beforeAdjustmentReading > startReading &&
          beforeAdjustmentReading <= endReading)
      ) {
        return beforeAdjustmentReading - startReading;
      }
    }

    return 0;
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 grow">
        <Heading className="text-xl leading-6" light>
          {t("balance_adjustment.create.title_harvesting")}
        </Heading>
        {balanceAdjustmentData.type ===
          BalanceAdjustmentType.WATER_HARVESTING &&
          balanceAdjustmentData.declarations?.map((declaration: any) => (
            <div
              className="w-full flex flex-col gap-4 mt-6"
              key={declaration.id}
            >
              <div className="w-1/5">
                <Label className="block text-gray-600">
                  {t("balance_adjustment.create.meter_field")}
                </Label>
                <div className="mt-1 flex">
                  <TextInput
                    value={declaration?.meter?.serialNo}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-1/4 flex flex-col justify-between">
                  <div>
                    <Label>
                      {t(
                        "balance_adjustment.create.before_adjustment_reading_field",
                      )}
                    </Label>
                    <p className="mt-1 font-bold text-3xl">
                      {formatVolume(declaration?.reading, "")}
                    </p>
                  </div>
                  <div>
                    <Label>
                      {t("balance_adjustment.create.last_read_at_field")}
                    </Label>
                    <TextInput
                      type="datetime-local"
                      value={
                        isDate(new Date(declaration.readAt))
                          ? formatDatetimeInput(new Date(declaration.readAt))
                          : declaration.readAt
                      }
                      disabled
                      className="w-4/6"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <Label>
                    {t("balance_adjustment.create.start_reading_field")}
                  </Label>
                  <DecimalInput
                    value={convertLiterToML(+declaration?.startReading!)}
                    onChange={value =>
                      setBalanceAdjustmentData((prevData: any) => ({
                        ...prevData,
                        declarations: prevData.declarations.map((decl: any) =>
                          decl.id === declaration.id
                            ? {
                                ...decl,
                                startReading: +value * 1000000,
                                adjustment: calculateAdjustment(
                                  declaration.reading,
                                  +value * 1000000,
                                  decl.endReading,
                                ),
                              }
                            : decl,
                        ),
                      }))
                    }
                  />
                  <Label>
                    {t("balance_adjustment.create.start_read_at_field")}
                  </Label>
                  <TextInput
                    type="datetime-local"
                    value={
                      isDate(declaration.startReadAt)
                        ? formatDatetimeInput(declaration.startReadAt)
                        : declaration.startReadAt
                    }
                    className="w-4/6"
                    onChange={e =>
                      setBalanceAdjustmentData((prevData: any) => ({
                        ...prevData,
                        declarations: prevData.declarations.map((decl: any) =>
                          decl.id === declaration.id
                            ? {
                                ...decl,
                                startReadAt: isDate(e.target.value)
                                  ? new Date(e.target.value)
                                  : e.target.value,
                              }
                            : decl,
                        ),
                      }))
                    }
                    required
                    min={
                      currentAccountingPeriod?.periodStart
                        ? formatDatetimeInput(
                            new Date(currentAccountingPeriod?.periodStart),
                          )
                        : undefined
                    }
                    max={
                      currentAccountingPeriod?.periodEnd
                        ? formatDatetimeInput(
                            new Date(currentAccountingPeriod?.periodEnd),
                          )
                        : undefined
                    }
                  />
                </div>
                <div className="w-1/3">
                  <Label>
                    {t("balance_adjustment.create.end_reading_field")}
                  </Label>
                  <DecimalInput
                    value={convertLiterToML(+declaration?.endReading!)}
                    onChange={value =>
                      setBalanceAdjustmentData((prevData: any) => ({
                        ...prevData,
                        declarations: prevData.declarations.map((decl: any) =>
                          decl.id === declaration.id
                            ? {
                                ...decl,
                                endReading: +value * 1000000,
                                adjustment: calculateAdjustment(
                                  declaration.reading,
                                  decl.startReading,
                                  +value * 1000000,
                                ),
                              }
                            : decl,
                        ),
                      }))
                    }
                  />
                  <Label>
                    {t("balance_adjustment.create.end_read_at_field")}
                  </Label>
                  <TextInput
                    type="datetime-local"
                    value={
                      isDate(declaration.endReadAt)
                        ? formatDatetimeInput(declaration.endReadAt)
                        : declaration.endReadAt
                    }
                    className="w-4/6"
                    onChange={e =>
                      setBalanceAdjustmentData((prevData: any) => ({
                        ...prevData,
                        declarations: prevData.declarations.map((decl: any) =>
                          decl.id === declaration.id
                            ? {
                                ...decl,
                                endReadAt: isDate(e.target.value)
                                  ? new Date(e.target.value)
                                  : e.target.value,
                              }
                            : decl,
                        ),
                      }))
                    }
                    required
                    min={
                      currentAccountingPeriod?.periodStart
                        ? formatDatetimeInput(
                            new Date(currentAccountingPeriod?.periodStart),
                          )
                        : undefined
                    }
                    max={
                      currentAccountingPeriod?.periodEnd
                        ? formatDatetimeInput(
                            new Date(currentAccountingPeriod?.periodEnd),
                          )
                        : undefined
                    }
                  />
                </div>
                <div className="w-1/3">
                  <Label>{t("declaration.allocation_usage")}</Label>
                  <p className="mt-1 font-bold text-3xl text-gray-400">
                    {formatVolume(declaration.adjustment * -1)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        {balanceAdjustmentData.type === BalanceAdjustmentType.METER_READING &&
          balanceAdjustmentData.declarations?.map((declaration: any) => (
            <div
              className="w-full flex flex-col gap-4 mt-6"
              key={declaration.id}
            >
              <div className="w-1/5">
                <Label className="block text-gray-600">
                  {t("balance_adjustment.create.meter_field")}
                </Label>
                <div className="mt-1 flex">
                  <TextInput
                    value={declaration?.meter?.serialNo}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-1/4">
                  <Label>
                    {declaration?.isInitial
                      ? t("declaration.initial_read")
                      : t(
                          "balance_adjustment.create.before_adjustment_reading_field",
                        )}
                  </Label>
                  <p className="mt-1 font-bold text-3xl">
                    {formatVolume(declaration?.reading, "")}
                  </p>
                </div>
                <div className="w-1/3">
                  <Label>
                    {t(
                      "balance_adjustment.create.after_adjustment_reading_field",
                    )}
                  </Label>
                  <DecimalInput
                    value={convertLiterToML(+declaration?.startReading!)}
                    onChange={value =>
                      setBalanceAdjustmentData((prevData: any) => ({
                        ...prevData,
                        declarations: prevData.declarations.map((decl: any) =>
                          decl.id === declaration.id
                            ? {
                                ...decl,
                                startReading: +value * 1000000,
                                endReading: +value * 1000000,
                                adjustment: declaration.isInitial
                                  ? 0
                                  : declaration.reading - +value * 1000000,
                              }
                            : decl,
                        ),
                      }))
                    }
                  />
                </div>
                <div className="w-1/3">
                  <Label>
                    {t("balance_adjustment.create.adjustment_field")}
                  </Label>
                  <p className="mt-1 font-bold text-3xl text-gray-400">
                    {declaration?.adjustment > 0 ? "+" : ""}
                    {formatVolume(declaration.adjustment)}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onGoBack}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary"
          disabled={balanceAdjustmentData?.declarations?.some(
            d =>
              (d.isInitial !== true && d.adjustment === 0) ||
              (d.isInitial === true && +d.reading === +d.startReading),
          )}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentEnterData;
