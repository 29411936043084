import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import Loading from "@components/shared/Loading";
import Heading from "@components/layout/Heading";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import WaterClassSummary from "./WaterClassSummary";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useSubscriberLevel0Resource } from "@hooks/query/useSubscriberLevel0Resource";
import { useAppContext } from "@context/AppContext";
import { formatVolume } from "@utils/formatVolume";
import { toastWarning } from "@utils/toast";
import {
  extractionRightTypes,
  getExtractionRightTypeIdentifier,
} from "@services/extractionRight";

const Level0WRSSummaryTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel } = useAppContext();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useSubscriber(subscriberId);
  const level1ResourceId = subscriber.level1ResourceId;
  const { data: level0Resource = {}, isLoading: isLevel0ResourceLoading } =
    useSubscriberLevel0Resource({
      params: {
        subscriberId,
        level0ResourceId,
      },
    });
  const { data: currentAccountingPeriod = {}, isLoading: isAPLoading } =
    useAllAccountingPeriods({
      params: {
        level1ResourceId,
        isActive: true,
      },
      options: {
        select: (res: any[]) => res?.[0],
        enabled: Boolean(level1ResourceId),
      },
    });

  React.useEffect(() => {
    setInfoPanel();
  }, [setInfoPanel]);

  if (isSubscriberLoading || isLevel0ResourceLoading || isAPLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const waterClasses = level0Resource?.waterClasses ?? [];
  const otherUsages: { label: string; usage: number; show: boolean }[] = [
    {
      label: t("approval.stock_and_domestic.title"),
      usage: level0Resource?.stockAndDomesticUsage,
      show: level0Resource?.otherClasses?.some(
        (oc: { type: string }) =>
          oc.type === getExtractionRightTypeIdentifier(extractionRightTypes.SD),
      ),
    },
    {
      label: t("declaration.water_harvesting"),
      usage: level0Resource?.waterHarvestingUsage,
      show: level0Resource?.otherClasses?.some(
        (oc: { type: string }) =>
          oc.type === getExtractionRightTypeIdentifier(extractionRightTypes.WH),
      ),
    },
    {
      label: t("subscriber.special_announcement"),
      usage: level0Resource?.specialAnnouncementUsage,
      show: level0Resource?.otherClasses?.some(
        (oc: { type: string }) =>
          oc.type === getExtractionRightTypeIdentifier(extractionRightTypes.SA),
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <header className="space-y-3">
        <Heading>
          {t("subscriber.level0wrs_accounts")}: {level0Resource?.identifier}
        </Heading>

        <div className="w-80">
          <Label htmlFor="accounting_period">
            {t("subscriber.select_accounting_period")}
          </Label>
          <SelectAccountingPeriod
            inputId="accounting_period"
            level1ResourceId={level1ResourceId}
            onChange={() => {
              toastWarning(
                t("subscriber.select_water_accounting_period_warning"),
              );
            }}
            value={{
              periodStart: new Date(currentAccountingPeriod?.periodStart),
              periodEnd: new Date(currentAccountingPeriod?.periodEnd),
            }}
            isSearchable={false}
            isClearable={false}
            openMenuOnFocus
          />
        </div>
      </header>

      {waterClasses.length <= 0 && (
        <div className="text-center space-y-6">
          <p className="whitespace-nowrap text-gray-400 text-center">
            {t("extraction_right.no_data")}
          </p>
          <Link
            className="inline-block btn-secondary"
            to={`/polestar/level1wrs/${level1ResourceId}/extraction_rights/create?subscriberId=${subscriberId}&level0ResourceId=${level0ResourceId}`}
          >
            {t("extraction_right.create.title")}
          </Link>
        </div>
      )}

      {waterClasses.length > 0 && (
        <div className="grid grid-cols-1 gap-6 2xl:grid-cols-10">
          <div className="col-span-7">
            <div className="space-y-6">
              {waterClasses.map((i: any) => {
                return (
                  <WaterClassSummary
                    key={i.id}
                    name={i.name}
                    extractionRightNames={i.extractionRights
                      ?.filter(
                        (i: any) =>
                          i.extractionRightTypeId !== extractionRightTypes.PT,
                      )
                      ?.map((i: any) => i.name)}
                    nominalVolume={i.volume}
                    announcedAllocation={
                      i.balance +
                      i.usage +
                      -1 * i.transfer -
                      i.forwardDrawVolume
                    }
                    allocationUsage={i.usage + i.overusedBalance}
                    transfer={i.transfer}
                    specialAnnouncement={i.specialAnnouncementUsage}
                    forwardDraw={i.forwardDrawVolume}
                    rate={i.rate * 100}
                  />
                );
              })}
            </div>
          </div>

          <aside className="col-span-3">
            <div className="rounded-t-lg relative overflow-hidden">
              <header
                className="px-4 py-3"
                style={{
                  backgroundColor: "#7ECCC2",
                }}
              >
                <Heading light className="md:text-xl">
                  {t("subscriber.volume_other")}
                </Heading>
              </header>
              <ul className="p-4 space-y-2 border border-t-0">
                {otherUsages
                  .filter(i => i.show)
                  .map(i => (
                    <li
                      key={"otherUsage--" + i.label}
                      className="flex justify-between"
                    >
                      <span className="text-gray-500">{i.label}</span>
                      <span className="text-lg text-primary-2 font-bold">
                        {formatVolume(i.usage)}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Level0WRSSummaryTab;
