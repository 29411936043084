import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { formatDate } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { formatVolume } from "@utils/formatVolume";
import { getDistributionLosses } from "@services/distributionLosses";

const DistributionLossesChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
  }>({
    fromDate: new Date(
      currentAccountingPeriodCheck?.accountingPeriod?.periodStart
    ),
    toDate:
      currentAccountingPeriodCheck?.accountingPeriod?.periodEnd &&
      new Date(currentAccountingPeriodCheck.accountingPeriod.periodEnd),
  });
  const tableFields = [
    { title: t("distribution_losses.timestamp"), name: "timestamp" },
    { title: t("subscriber.name"), name: "subscriberName" },
    { title: t("subscriber.account_number"), name: "subscriberAccountNumber" },
    {
      title: `${t("distribution_losses.volume")} (${t("common.volume_unit")})`,
      name: "volume",
    },
    { title: t("common.level0wrs"), name: "level0Resource" },
  ];

  const { isLoading, refetch } = useQuery(
    ["distributionLosses", filter],
    () =>
      getDistributionLosses({
        periodStart: filter?.fromDate,
        periodEnd: filter?.toDate,
        level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      enabled: !!filter?.fromDate && !!filter?.toDate,
      onSuccess: (response) => {
        setData(
          response?.map((record: any) => ({
            ...record,
            subscriberName: record.extractionPoint?.subscriber?.name,
            subscriberAccountNumber:
              record.extractionPoint?.subscriber?.accountNumber,
            level0Resource: record.extractionPoint?.level0WRS?.identifier,
            volume: formatVolume(record.volume, "").trim(),
            timestamp: formatDateTime(new Date(record.readAt)),
          }))
        );
      },
    }
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col p-4 grow">
        <Heading1 light>
          {t("accounting_period_checks.distribution_losses")}{" "}
          <strong className="ml-6">{data?.length}</strong>
        </Heading1>
        <div className="flex flex-col grow gap-4">
          <form className="flex flex-wrap gap-3 justify-start">
            <div className="w-full sm:w-auto">
              <Label htmlFor="from-date">{t("reporting.filter_dates")}</Label>
              <div className="flex flex-col sm:flex-row gap-1">
                <div className="max-w-max">
                  <TextInput
                    type="date"
                    placeholder="From"
                    value={
                      filter.fromDate &&
                      formatDate(filter.fromDate, "yyyy-MM-dd")
                    }
                    onChange={(e) => {
                      handleFilterChange(
                        "fromDate",
                        new Date(`${e.target.value}T00:00:00`)
                      );
                    }}
                  />
                </div>
                <ArrowRightCalendarIcon className="w-10 h-10 hidden sm:block" />
                <div className="max-w-max">
                  <TextInput
                    type="date"
                    placeholder="To"
                    value={
                      filter.toDate && formatDate(filter.toDate, "yyyy-MM-dd")
                    }
                    min={
                      filter.fromDate &&
                      formatDate(filter.fromDate, "yyyy-MM-dd")
                    }
                    onChange={(e) => {
                      handleFilterChange(
                        "toDate",
                        new Date(`${e.target.value}T23:59:59`)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-end sm:w-auto">
              <button
                type="button"
                className="btn-default"
                onClick={() => refetch()}
              >
                {t("common.refresh")}
              </button>
            </div>
            <div className="flex items-end w-full ml-auto sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1 w-auto"
                to="/polestar/distribution_losses/create?newWindow=true"
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("accounting_period_checks.add_distribution_loss")}
              </Link>
            </div>
          </form>
          <Table
            fields={tableFields}
            data={data}
            pageSize={100}
            loading={isLoading}
            stickyHeader
          />
        </div>
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          distributionLossesCheckedAt: new Date(),
          distributionLossesCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default DistributionLossesChecklist;
