import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import ForwardDrawsTable from "@components/table/administrative_approvals_table/ForwardDrawsTable";
import SeasonalTransferTable from "@components/table/administrative_approvals_table/SeasonalTransferTable";
import PermanentTransferList from "@components/form/admin_approvals/PermanentTransferList";
import SpecialAnnouncementTable from "@components/table/administrative_approvals_table/SpecialAnnouncementTable";
import StockAndDomesticTable from "@components/table/StockAndDomesticTable";
import AmalgamateAndSubdivisionTable from "@components/table/administrative_approvals_table/AmalgamateAndSubdivisionTable";
import BalanceAdjustmentTable from "@components/table/BalanceAdjustmentTable";
import { BalanceAdjustmentType } from "@context/BalanceAdjustmentContext";

const ApprovalIndex: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout
      permissions={["ViewApprovals"]}
      breadcrumb={[
        {
          label: t("common.home"),
          href: "/polestar",
        },
        {
          label: t("common.administrative_approvals"),
        },
      ]}
      title={t("common.administrative_approvals")}
    >
      <TabPanels
        className="bg-white rounded-lg"
        keepSelectionOnRefresh={true}
        tabs={[
          {
            title: t("approval.seasonal_water_assignments.short_title"),
            panel: <SeasonalTransferTable />,
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>
                    {t(
                      "approval.seasonal_water_assignments.tab_info_panel.intro"
                    )}
                  </p>
                  <p>
                    {t(
                      "approval.seasonal_water_assignments.tab_info_panel.conclusion"
                    )}
                  </p>
                  <StatusInfo />
                </div>
              </InfoPanel>
            ),
          },
          {
            title: t("approval.permanent_trades.short_title"),
            panel: <PermanentTransferList />,
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>{t("approval.permanent_trades.tab_info_panel.intro")}</p>
                  <p>
                    {t("approval.permanent_trades.tab_info_panel.conclusion")}
                  </p>
                  <StatusInfo />
                </div>
              </InfoPanel>
            ),
          },
          {
            title: t("approval.forward_draws.title"),
            panel: <ForwardDrawsTable />,
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>{t("approval.forward_draws.tab_info_panel.intro")}</p>
                  <p>{t("approval.forward_draws.tab_info_panel.conclusion")}</p>
                  <StatusInfo />
                </div>
              </InfoPanel>
            ),
          },
          {
            title: t("approval.stock_and_domestic.title"),
            panel: <StockAndDomesticTable />,
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>{t("approval.stock_and_domestic.tab_info.intro")}</p>
                  <p>{t("approval.stock_and_domestic.tab_info.conclusion")}</p>
                  <StatusInfo />
                </div>
              </InfoPanel>
            ),
          },
          {
            title: t("approval.subdivide_and_amalgamate.title"),
            panel: <AmalgamateAndSubdivisionTable />,
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>
                    {t(
                      "approval.subdivide_and_amalgamate.tab_info_panel.intro"
                    )}
                  </p>
                  <p>
                    {t(
                      "approval.subdivide_and_amalgamate.tab_info_panel.conclusion"
                    )}
                  </p>
                  <StatusInfo />
                </div>
              </InfoPanel>
            ),
          },
          {
            title: t("balance_adjustment.water_harvesting.title"),
            panel: (
              <BalanceAdjustmentTable
                type={BalanceAdjustmentType.WATER_HARVESTING}
              />
            ),
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>
                    {t(
                      "balance_adjustment.water_harvesting.list.tab_info_panel_1"
                    )}
                  </p>
                  <p>
                    {t(
                      "balance_adjustment.water_harvesting.list.tab_info_panel_2"
                    )}
                  </p>
                  <p>
                    {t(
                      "balance_adjustment.water_harvesting.list.tab_info_panel_3"
                    )}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            title: t("notification.type_special_announcement"),
            panel: <SpecialAnnouncementTable />,
            tabInfoPanel: (
              <InfoPanel>
                <div className="space-y-4">
                  <p>{t("notification.info_panel.dashboard_intro")}</p>
                  <p>{t("notification.info_panel.dashboard_conc")}</p>
                  <StatusInfo
                    onlyShow={["active", "ended"]}
                    descriptionKey="approval.info.status.announcement_description"
                  />
                </div>
              </InfoPanel>
            ),
          },
        ]}
      />
    </Layout>
  );
};

export const StatusInfo = ({
  onlyShow,
  descriptionKey = "approval.info.status.description",
}: {
  onlyShow?: Array<string>;
  descriptionKey?: string;
}) => {
  const { t } = useTranslation();
  const defaultStatuses = onlyShow
    ? onlyShow
    : [
        "pending",
        "approved",
        "rejected",
        "require_info",
        "in_draft",
        "abandoned",
      ];

  const description = t(descriptionKey);

  return (
    <div>
      <p className="mb-2">{description}</p>
      <ul className="list-inside list-disc space-y-1 pl-2">
        {defaultStatuses.map((value) => {
          return (
            <li key={`forwardDrawStatus--${value}`}>
              <strong>{t(`common.${value}`)}:</strong>{" "}
              {t(`approval.info.status.${value}`)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ApprovalIndex;
