import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SeasonalAssignmentModal from "@components/modal/SeasonalAssignmentModal";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import {
  getStatusAndText,
  getActionTimestamp,
} from "@utils/administrativeApproval";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { AdministrativeApprovalType } from "@services/administrativeApprovals";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { useGetAdministrativeApproval } from "@hooks/query/useGetAdministrativeApproval";
import { useAppContext } from "@context/AppContext";

type SeasonalTransferTableProps = {
  level1Resource?: any;
};

const SeasonalTransferTable: React.FunctionComponent<
  SeasonalTransferTableProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState("");
  const { checkPermissions } = useAppContext();
  const { data: approval } = useGetAdministrativeApproval(selectedId);
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
    level1wrsId?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const {
    data: seasonalAssignments = [],
    isLoading,
    refetch,
  } = useAllAdministrativeApprovals({
    params: {
      type: AdministrativeApprovalType.SWT,
    },
  });

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1Resource && (
            <div>
              <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}

          {level1Resource || filter.level1wrsId ? (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={level1Resource.id || filter.level1wrsId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.perideStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="customer-name">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customer-name"
              value={filter.customerName}
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={seasonalAssignments}
            />
          </div>
        </form>
        {checkPermissions(["CreateApprovals"]) && (
          <Link
            to={`/polestar/administrative_approvals/seasonal_transfer${
              level1Resource ? `?level1ResourceId=${level1Resource.id}` : ""
            }`}
            className="btn-secondary text-sm rounded whitespace-nowrap"
          >
            {t("approval.seasonal_water_assignments.add_new")}
          </Link>
        )}
      </header>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("approval.seasonal_water_assignments.from_subscriber"),
            name: "sellerName",
          },
          {
            title: t("approval.seasonal_water_assignments.to_subscriber"),
            name: "buyerName",
          },
          {
            title: t("common.volume"),
            name: "volume",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("approval.seasonal_water_assignments.timestamp"),
            name: "timestamp",
          },
          {
            title: t("common.effective_date"),
            name: "effectiveDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={seasonalAssignments
          ?.filter(
            (row: { level1Resource: any }) =>
              level1Resource === undefined ||
              row.level1Resource?.id === level1Resource?.id,
          )
          ?.filter(
            (row: { level1Resource: any }) =>
              filter.level1wrsId === undefined ||
              row.level1Resource?.id === filter.level1wrsId,
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              filter.accountingPeriod === undefined ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status,
          )
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.seller?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()) ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.map((app: any) => {
            const { status, text } = getStatusAndText(app?.status as number);
            return {
              ...app,
              level1Resource: app.level1Resource.identifier,
              createdAt: formatDate(new Date(app.lodgedAt)),
              effectiveDate: formatDate(new Date(app.effectiveDate)),
              sellerName: app.seller.name,
              buyerName: app.buyer.name,
              volume: formatVolume(app.volume),
              accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
              status: app.status ? (
                <Tag status={status ?? "default"}>{text}</Tag>
              ) : (
                <></>
              ),
              timestamp: getActionTimestamp(app),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedId(app.id);
                    }}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />
      {selectedId && approval ? (
        <SeasonalAssignmentModal
          data={approval}
          onClose={() => {
            setSelectedId("");
          }}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};

export default SeasonalTransferTable;
