import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useCreateSeasonalTransfer } from "@hooks/mutation/useCreateSeasonalTransfer";
import { convertMLToLiter } from "@utils/convertUnits";
import { toastError, toastSuccess } from "@utils/toast";
import { DBTables } from "@utils/constants/dbTables";

const SeasonalTransferLedger = () => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const {
    stepHelpers,
    details,
    setWorkflowCompleted,
    setWorkflowInstances,
    navigateForCancel,
    setTransferIds,
    info,
  } = useSeasonalTransferContext();
  const { uploadEvidences, isSubmitting, getEvidencesInfo } =
    useEvidenceContext();
  const { mutateAsync: manualApproveMutation, isLoading } =
    useCreateSeasonalTransfer();

  const handleConfirm = async () => {
    try {
      const [res, workflowInstance] = await manualApproveMutation({
        from: {
          subscriberId: details.from.subscriber.id,
          level0ResourceId: details.from.level0Resource.id,
          waterClassId: details.from.waterClass.id,
        },
        to: {
          subscriberId: details.to.subscriber.id,
          level0ResourceId: details.to.level0Resource.id,
          waterClassId: details.to.waterClass.id,
        },
        transferVolume: convertMLToLiter(+details.transferVolume || 0),
        price: +details.price || 0,
        description: details.description,
        lodgedAt: new Date(),
        effectiveDate: details.effectiveDate,
      });

      setTransferIds((prevState: any) => [...prevState, res.id]);
      setWorkflowInstances((prevState: any) => [
        ...prevState,
        workflowInstance,
      ]);

      await uploadEvidences({
        description: t(
          "approval.seasonal_water_assignments.create.step_6.description",
          {
            sellerName: details.from.subscriber.name,
            buyerName: details.to.subscriber.name,
          }
        ),
        references: [
          {
            referenceId: res.id,
            referenceTable: DBTables.AdministrativeApproval,
          },
          {
            referenceId: details.from.subscriber.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: details.to.subscriber.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: workflowInstance?.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      setWorkflowCompleted(true);
      setShowConfirmModal(false);
      toastSuccess(
        t("approval.seasonal_water_assignments.create.success.approve")
      );
    } catch (error: any) {
      toastError(
        t("approval.seasonal_water_assignments.create.error.approve", {
          error: error?.response?.data?.message || error?.message,
        })
      );
    }
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow">
        <Heading light>{t("permanent_trade.confirm_transfer")}</Heading>
        <ConfirmationDetail
          data={[
            info.level1Resource(),
            info.fromSubscriber(),
            info.volumes(),
            info.toSubscriber(),
            info.destination(),
            info.details(),
            getEvidencesInfo(),
          ]}
          onEdit={stepHelpers.setStep}
        />
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
          disabled={isSubmitting || isLoading}
        >
          {t("common.ledger")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={isLoading || isSubmitting}
      >
        {t("approval.seasonal_water_assignments.create.step_7.confirm_approve")}
      </ConfirmModal>
    </>
  );
};

export default SeasonalTransferLedger;
