import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import DecimalInput from "@components/form/DecimalInput";
import TextInput from "@components/form/TextInput";
import TextArea from "@components/form/TextArea";
import RadioInput from "@components/form/RadioInput";
import { useAdjustDeclarationContext } from "@context/AdjustDeclarationContext";
import { formatVolume } from "@utils/formatVolume";

type EnterAdjustDeclarationDetailsProps = {
  onPrevious: () => void;
  onCancel: () => void;
  onNext: () => void;
};

const EnterAdjustDeclarationDetails: React.FunctionComponent<
  EnterAdjustDeclarationDetailsProps
> = ({ onPrevious, onNext, onCancel }) => {
  const { t } = useTranslation();
  const { details, setDetails } = useAdjustDeclarationContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form className="p-6 flex flex-col grow gap-6" onSubmit={handleSubmit}>
      <Heading light>{t("balance_adjustment.meter_reading.reset")}</Heading>

      <div className="max-w-md">
        <Label>{t("meter.serial_no")}</Label>
        <TextInput value={details.serialNo} readOnly disabled />
      </div>

      <fieldset>
        <div className="flex gap-6 mt-4">
          <RadioInput
            name="type"
            id="meter"
            label={t("balance_adjustment.meter_reading.reading")}
            checked={details.type === "reading"}
            onChange={(e) => {
              if (e.target.checked) {
                setDetails((prev) => ({ ...prev, type: "reading" }));
              }
            }}
            inline
          />
          <RadioInput
            name="type"
            id="usage"
            label={t("declaration.volume")}
            checked={details.type === "usage"}
            onChange={(e) => {
              if (e.target.checked) {
                setDetails((prev) => ({ ...prev, type: "usage" }));
              }
            }}
            inline
          />
        </div>
      </fieldset>

      <fieldset className="flex gap-6">
        <div className="flex">
          {details.type === "reading" &&
          details.currentReading &&
          details.reading ? (
            <div className="flex gap-6">
              <div>
                <Label>{t("declaration.form.last_read")}</Label>
                <p className="font-bold text-3xl">{details.currentReading}</p>
              </div>
              <div>
                <Label htmlFor="reading">
                  {t("balance_adjustment.meter_reading.new_reading")}
                </Label>
                <DecimalInput
                  id="reading"
                  value={details.reading}
                  onChange={(reading) => {
                    setDetails((prev) => ({
                      ...prev,
                      reading,
                    }));
                  }}
                />
              </div>
            </div>
          ) : null}

          {details.type === "usage" && (
            <div className="flex gap-6">
              <div>
                <Label>
                  {t("balance_adjustment.meter_reading.last_usage")}
                </Label>
                <p className="font-bold text-3xl">
                  {details.currentVolume} {t("common.volume_unit")}
                </p>
              </div>
              <div>
                <Label htmlFor="volume">
                  {t("balance_adjustment.meter_reading.new_usage")}
                </Label>
                <TextInput
                  type="number"
                  id="volume"
                  step={0.001}
                  value={details.volume}
                  suffix={t("common.volume_unit")}
                  onChange={(e) => {
                    setDetails((prev) => ({
                      ...prev,
                      volume: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="w-1/3">
                <Label>{t("declaration.change_in_volume")}</Label>
                <p className="mt-1 font-bold text-3xl text-gray-400">
                  {Number(Number(details.currentVolume)) <
                  Number(details.volume)
                    ? ""
                    : "+"}
                  {formatVolume(
                    -(
                      Number(details.volume) * 1_000_000 -
                      Number(details.currentVolume) * 1_000_000
                    )
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </fieldset>

      <div className="max-w-xl">
        <Label htmlFor="note" optional>
          {t("common.note")}
        </Label>
        <TextArea
          id="note"
          value={details.note}
          onChange={(e: any) =>
            setDetails((prev) => ({
              ...prev,
              note: e.target.value,
            }))
          }
          rows={8}
        />
      </div>

      <div className="grow" />

      <footer className="flex gap-4 -mx-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={
            details.currentReading === details.reading &&
            details.currentVolume === details.volume
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterAdjustDeclarationDetails;
