import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import SearchInput from "@components/form/SearchInput";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Modal from "@components/layout/Modal";
import Table from "@components/layout/Table";
import { useAppContext } from "@context/AppContext";
import { BalanceAdjustmentType } from "@context/BalanceAdjustmentContext";
import { getBalanceAdjustments } from "@services/balanceAdjustment";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import ENV from "@config/env";

type BalanceAdjustmentTableProps = {
  level1ResourceId?: string;
  periodStart?: Date;
  periodEnd?: Date;
  openNewWindow?: boolean;
  accountingPeriod?: any;
  type?: BalanceAdjustmentType;
  onLoaded?: (data: any[]) => void;
};

const BalanceAdjustmentTable: FunctionComponent<
  BalanceAdjustmentTableProps
> = ({
  level1ResourceId,
  accountingPeriod,
  periodStart,
  periodEnd,
  type,
  openNewWindow = false,
  onLoaded,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
    level1wrsId?: string;
  }>({
    accountingPeriod: accountingPeriod || {
      id: "",
      periodStart: periodStart!,
      periodEnd: periodEnd!,
    },
  });

  const { data: balanceAdjustments, isLoading } = useQuery(
    ["balance_adjustments"],
    async () => await getBalanceAdjustments({ type }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onSuccess: response => {
        onLoaded?.(response);
      },
    },
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1ResourceId && (
            <div>
              <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}
          {(!level1ResourceId && filter.level1wrsId) || accountingPeriod ? (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={filter.level1wrsId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.perideStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="customerName">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customerName"
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
        </form>

        <div className="flex items-end w-full ml-auto sm:w-auto">
          {checkPermissions(["CreateBalanceAdjustments"]) && (
            <Link
              to={`/polestar/balance_adjustments/create?${
                openNewWindow ? "newWindow=true" : ""
              }${
                level1ResourceId ? `&level1ResourceId=${level1ResourceId}` : ""
              }`}
              className="btn-secondary text-sm rounded whitespace-nowrap flex gap-1 items-center"
              target={openNewWindow ? "_blank" : "_self"}
            >
              {openNewWindow && <ExternalLinkIcon className="w-4 h-4" />}
              {t("balance_adjustment.water_harvesting.add_new")}
            </Link>
          )}
        </div>
      </header>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("balance_adjustment.create.type"),
            name: "type",
          },
          {
            title: t("common.subscriber"),
            name: "subscriberName",
          },
          {
            title: t("common.level0wrs"),
            name: "level0Resource",
          },
          {
            title: t("declaration.allocation_usage"),
            name: "adjustment",
          },
          {
            title: t("common.actioned_at"),
            name: "timestamp",
          },
          {
            title: t("common.effective_date"),
            name: "effectiveDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={balanceAdjustments
          ?.filter(
            (row: { level0Resource: any }) =>
              filter.level1wrsId === undefined ||
              row.level0Resource?.parentId === filter.level1wrsId,
          )
          ?.filter(
            (row: { declarations: any }) =>
              (filter.accountingPeriod?.periodStart === undefined &&
                filter.accountingPeriod?.periodEnd === undefined) ||
              row.declarations?.some(
                (x: any) =>
                  (new Date(filter.accountingPeriod?.periodStart!) <=
                    new Date(x.startReadAt) &&
                    new Date(x.startReadAt) <=
                      new Date(filter.accountingPeriod?.periodEnd!)) ||
                  row.declarations?.some(
                    (x: any) =>
                      new Date(filter.accountingPeriod?.periodStart!) <=
                        new Date(x.endReadAt) &&
                      new Date(x.endReadAt) <=
                        new Date(filter.accountingPeriod?.periodEnd!),
                  ),
              ),
          )
          ?.filter(
            (row: { subscriber: any }) =>
              !filter?.customerName?.length ||
              row.subscriber?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.map((app: any) => {
            return {
              ...app,
              type:
                Number(app.type) === BalanceAdjustmentType.WATER_HARVESTING
                  ? t("balance_adjustment.water_harvesting.title")
                  : t("balance_adjustment.meter_reading.title"),
              level1Resource: app.level0Resource?.parent?.identifier,
              level0Resource: app.level0Resource?.identifier,
              subscriberName: app.subscriber?.name,
              extractionRightName: app.extractionRight?.name,
              timestamp: formatDate(new Date(app.createdAt)),
              effectiveDate: formatDate(new Date(app.effectiveDate)),
              adjustment: formatVolume(
                -1 *
                  app.declarations?.reduce(
                    (a: any, b: any) => a + +b.adjustment,
                    0,
                  ) ?? 0,
              ),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedItem(app);
                    }}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />
      <Modal open={selectedItem}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("balance_adjustment.title")}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.type")}:
                </dt>
                <dd className="text-gray-500">
                  {+selectedItem?.type ===
                  BalanceAdjustmentType.WATER_HARVESTING
                    ? t("balance_adjustment.water_harvesting.title")
                    : t("balance_adjustment.meter_reading.title")}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.level1wrs")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedItem?.level0Resource?.parent?.name}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.subscriber")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedItem?.subscriber?.name}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.level0wrs")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedItem?.level0Resource?.name}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.actioned_at")}:
                </dt>
                <dd className="text-gray-500">
                  {formatDate(new Date(selectedItem?.createdAt))}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.effective_date")}:
                </dt>
                <dd className="text-gray-500">
                  {formatDate(new Date(selectedItem?.effectiveDate))}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("declaration.allocation_usage")}:
                </dt>
                <dd className="text-gray-500">
                  {formatVolume(
                    -1 *
                      selectedItem?.declarations?.reduce(
                        (a: any, b: any) => a + +b.adjustment,
                        0,
                      ) ?? 0,
                  )}
                </dd>
              </div>
              {selectedItem?.notes && (
                <div className="col-span-2 sm:col-span-1 flex gap-1">
                  <dt className="font-medium text-gray-900">
                    {t("balance_adjustment.notes")}:
                  </dt>
                  <blockquote className="border-l-4 pl-4 whitespace-pre-wrap">
                    {selectedItem?.notes}
                  </blockquote>
                </div>
              )}
              {selectedItem?.evidences?.[0] && (
                <div className="col-span-2 sm:col-span-1 flex gap-1">
                  <dt className="font-medium text-gray-900">
                    {t("balance_adjustment.evidences")}:
                  </dt>
                  <dd className="text-gray-500">
                    <a
                      href={`${ENV.API_HOST}/evidences/${selectedItem?.evidences?.[0]?.id}/download`}
                      download
                    >
                      {t("common.attachment_link", {
                        fileName: selectedItem?.evidences?.[0]?.fileName,
                      })}
                    </a>
                  </dd>
                </div>
              )}
            </dl>
            <fieldset className="w-full">
              <Legend>{t("common.details")}</Legend>
              {+selectedItem?.type ===
                BalanceAdjustmentType.WATER_HARVESTING && (
                <Table
                  fields={[
                    {
                      title: t("common.meter"),
                      name: "meter",
                    },
                    {
                      title: t(
                        "balance_adjustment.create.before_adjustment_reading_field",
                      ),
                      name: "beforeAdjustmentReading",
                    },
                    {
                      title: t("balance_adjustment.create.start_reading_field"),
                      name: "startReading",
                    },
                    {
                      title: t("balance_adjustment.create.end_reading_field"),
                      name: "endReading",
                    },
                    {
                      title: t("declaration.allocation_usage"),
                      name: "adjustment",
                    },
                  ]}
                  data={selectedItem?.declarations?.map((x: any) => ({
                    meter: x.meter?.serialNo,
                    beforeAdjustmentReading: formatVolume(
                      x.beforeAdjustmentReading,
                      "",
                    ),
                    startReading: formatVolume(x.startReading, ""),
                    endReading: formatVolume(x.endReading, ""),
                    adjustment: formatVolume(-1 * x.adjustment),
                  }))}
                />
              )}
              {+selectedItem?.type === BalanceAdjustmentType.METER_READING && (
                <Table
                  fields={[
                    {
                      title: t("common.meter"),
                      name: "meter",
                    },
                    {
                      title: t(
                        "balance_adjustment.create.before_adjustment_reading_field",
                      ),
                      name: "beforeAdjustmentReading",
                    },
                    {
                      title: t(
                        "balance_adjustment.create.after_adjustment_reading_field",
                      ),
                      name: "endReading",
                    },
                    {
                      title: t("balance_adjustment.create.adjustment_field"),
                      name: "adjustment",
                    },
                  ]}
                  data={selectedItem?.declarations?.map((x: any) => ({
                    meter: x.meter?.serialNo,
                    beforeAdjustmentReading: formatVolume(
                      x.beforeAdjustmentReading,
                      "",
                    ),
                    startReading: formatVolume(x.startReading, ""),
                    endReading: formatVolume(x.endReading, ""),
                    adjustment: formatVolume(x.adjustment),
                  }))}
                />
              )}
            </fieldset>
          </div>
          <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
            <button
              type="button"
              className="btn-primary justify-end text-base font-medium sm:col-start-1 sm:text-sm"
              onClick={() => setSelectedItem(undefined)}
            >
              {t("common.close")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BalanceAdjustmentTable;
