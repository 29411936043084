import Select from "react-select";
import classNames from "classnames";
import { isEqual } from "date-fns";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllAccountingPeriods } from "@hooks/query/zenith/useAllAccoutingPeriods";
import { getAccountingPeriodOptions } from "@utils/getAccountingPeriodOptions";

type SelectProps = React.ComponentProps<Select>;

type SelectAccountingPeriodProps = SelectProps & {
  value?: {
    periodStart: Date;
    periodEnd: Date;
  };
  level1ResourceId?: string;
  errorMessage?: string;
};

const SelectAccountingPeriod: React.FunctionComponent<
  SelectAccountingPeriodProps
> = ({ value, className, level1ResourceId, errorMessage, ...props }) => {
  const { data = [], isLoading } = useAllAccountingPeriods({
    params: {
      level1ResourceId,
    },
  });

  if (isLoading) {
    return null;
  }

  const options = getAccountingPeriodOptions(data);

  return (
    <>
      <Select
        options={options}
        value={
          value
            ? options.find(
                (i: any) =>
                  isEqual(new Date(i.value?.periodStart), value?.periodStart) &&
                  isEqual(new Date(i.value?.periodEnd), value?.periodEnd),
              )
            : undefined
        }
        className={classNames("text-sm", className)}
        isClearable
        openMenuOnFocus
        {...props}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectAccountingPeriod;
