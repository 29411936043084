import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Loading from "@components/shared/Loading";
import { useAppContext } from "@context/AppContext";
import { useAllExchangeRates } from "@hooks/query/useAllExchangeRates";
import { formatPercentage } from "@utils/formatPercentage";
import { formatDate } from "@utils/formatDate";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import ExchangeRateModal from "@components/modal/ExchangeRateModal";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";

type Props = {
  level1Resource: any;
};

const ExchangeRateTable: React.FunctionComponent<Props> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [selectedExchangeRate, setSelectedExchangeRate] = React.useState<any>();

  const [filter, setFilter] = React.useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    priorityName?: string;
    level0ResourceIdentifier?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const { data: exchangeRates = [], isLoading } = useAllExchangeRates({
    params: { level1ResourceId: level1Resource.id },
  });

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const tableFields = [
    {
      title: t("level0wrs.identifier"),
      name: "level0ResourceIdentifier",
    },
    {
      title: t("water_class.name"),
      name: "priorityName",
    },
    {
      title: t("common.allocation"),
      name: "rate",
    },
    {
      title: t("exchange_rate.created_at"),
      name: "createdAt",
    },
    {
      title: t("exchange_rate.effective_date"),
      name: "effectiveDate",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = exchangeRates
    .filter((item: any) => item.waterClass?.name !== "Quasi")
    .map((item: any) => {
      return {
        ...item,
        level0ResourceIdentifier: item.waterClass?.level0Resource?.identifier,
        priorityName: item.waterClass?.name,
        rate: formatPercentage(item.rate),
        createdAt: formatDate(new Date(item.createdAt)),
        effectiveDate: formatDate(
          new Date(item.waterClass.level0Resource.periodEnd),
        ),
        actions: (
          <Select
            placeholder={t("common.actions")}
            options={[
              ...(checkPermissions(["ViewAllocationAnnouncement"])
                ? [
                    {
                      label: t("common.view"),
                      value: item.id,
                      data: item,
                    },
                  ]
                : []),
            ]}
            onChange={e => {
              if (e?.data) {
                setSelectedExchangeRate(e?.data);
              }
            }}
            controlShouldRenderValue={false}
            menuPortalTarget={document.body}
            isSearchable={false}
          />
        ),
      };
    })
    ?.filter(
      (row: { priorityName: string }) =>
        filter.priorityName === undefined ||
        row.priorityName
          .toLowerCase()
          .includes(filter.priorityName.toLowerCase()),
    )
    ?.filter(
      (row: { level0ResourceIdentifier: string }) =>
        filter.level0ResourceIdentifier === undefined ||
        row.level0ResourceIdentifier === filter.level0ResourceIdentifier,
    )
    ?.filter(
      (item: { accountingPeriod: any }) =>
        filter.accountingPeriod === undefined ||
        item.accountingPeriod?.id === filter.accountingPeriod?.id,
    );

  return (
    <>
      <header className="flex items-end gap-4 relative z-20">
        {level1Resource ? (
          <div className="w-60">
            <Label htmlFor="accountingPeriod">
              {t("common.accounting_period")}
            </Label>
            <SelectAccountingPeriod
              inputId="accountingPeriod"
              level1ResourceId={level1Resource.id}
              value={filter.accountingPeriod}
              onChange={(e: any) => {
                handleFilterChange("accountingPeriod", {
                  id: e?.value?.id,
                  periodStart: e?.value?.perideStart
                    ? new Date(e?.value?.periodStart)
                    : "",
                  periodEnd: e?.value?.periodEnd
                    ? new Date(e?.value?.periodEnd)
                    : "",
                });
              }}
            />
          </div>
        ) : null}

        <div className="w-40">
          <Label htmlFor="level0Resource">
            {t("water_class.filter.level0Resource")}
          </Label>
          <SelectLevel0Resource
            inputId="level0Resource"
            level1ResourceId={level1Resource.id}
            onChange={e => {
              handleFilterChange(
                "level0ResourceIdentifier",
                e?.label || undefined,
              );
            }}
            value={filter?.level0ResourceIdentifier}
            isClearable
          />
        </div>
        <div>
          <Label htmlFor="name">{t("water_class.filter.name")}</Label>
          <SearchInput
            id="name"
            onChange={e =>
              handleFilterChange("priorityName", e.target.value.toLowerCase())
            }
          />
        </div>

        {checkPermissions(["CreateAllocationAnnouncement"]) ? (
          <Link
            className="ml-auto btn-secondary text-sm rounded"
            to={`/polestar/exchange_rates/create`}
          >
            {t("exchange_rate.add_new")}
          </Link>
        ) : null}
      </header>

      <Table
        fields={tableFields}
        data={tableData}
        stickyHeader
        tableHeaderClassName="relative z-10"
        loading={isLoading}
        containerClassName="scrollbar-hide"
      />
      <ExchangeRateModal
        data={selectedExchangeRate}
        onClose={() => setSelectedExchangeRate(undefined)}
      />
    </>
  );
};

export default ExchangeRateTable;
