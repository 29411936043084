import { useTranslation } from "react-i18next";

import SelectLevel1Resource from "./SelectLevel1Resource";
import SelectSubscriber from "./SelectSubscriber";
import SelectExtractionRight from "./SelectExtractionRight";
import EnterDetails from "./EnterDetails";
import ConfirmCreateStockAndDomestic from "./ConfirmCreateStockAndDomestic";
import WorkflowPanels from "@components/form/WorkflowPanels";
import StepButton from "@components/shared/StepButton";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import StepInfo from "@components/shared/StepInfo";
import {
  StockAndDomesticApprovalProvider,
  useStockAndDomesticApprovalContext,
} from "@context/StockAndDomesticApprovalContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useCreateStockAndDomestic } from "@hooks/mutation/useCreateStockAndDomestic";
import { DBTables } from "@utils/constants/dbTables";
import { convertMLToLiter } from "@utils/convertUnits";
import EffectiveDateForm from "@components/form/EffectiveDateForm";
import AdministrativeApprovalEffectiveDateInformation from "@components/shared/AdministrativeApprovalEffectiveDateInformation";

const CreateStockAndDomesticWorkflow = () => {
  return (
    <EvidenceProvider>
      <StockAndDomesticApprovalProvider>
        <Consumer />
      </StockAndDomesticApprovalProvider>
    </EvidenceProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();

  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const {
    details,
    setDetails,
    currentStep,
    stepHelpers,
    info,
    isComplete,
    handleCancel,
    setWorkflowInstance,
  } = useStockAndDomesticApprovalContext();

  const { mutateAsync: createSDMutation } = useCreateStockAndDomestic();

  const handleSubmit = async () => {
    const [res, workflowInstance] = await createSDMutation({
      level1ResourceId: details.level1Resource.id,
      extractionRightId: details.extractionRight.id,
      volume: details.volume ? convertMLToLiter(+details.volume) : 0,
      reason: details.reason,
      effectiveDate: details.effectiveDate,
      lodgedAt: new Date(),
    });

    await uploadEvidences({
      description: t("approval.stock_and_domestic.evidence_description", {
        extractionRightName: details.extractionRight.name,
        subscriberName: details.subscriber.name,
      }),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.AdministrativeApproval,
        },
        {
          referenceId: details.subscriber.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: details.level1Resource.id,
          referenceTable: DBTables.WRSHierarchies,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });

    setWorkflowInstance(workflowInstance);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<StepInfo data={info.scheme.body} />}
          isOnChain={isComplete}
        >
          {t("approval.permanent_trades.select_level1wrs")}
        </StepButton>
      ),
      panel: <SelectLevel1Resource />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.stock_and_domestic.info.select_level1wrs")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={info.subscriber.body} />}
          isOnChain={isComplete}
        >
          {t("approval.stock_and_domestic.select_subscriber")}
        </StepButton>
      ),
      panel: <SelectSubscriber />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.stock_and_domestic.info.select_subscriber")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          info={<StepInfo data={info.extractionRight.body} />}
          isOnChain={isComplete}
        >
          {t("permanent_trade.select_extraction_right")}
        </StepButton>
      ),
      panel: <SelectExtractionRight />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>
              {t("approval.stock_and_domestic.info.select_extraction_right")}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={info.details.body} />}
          isOnChain={isComplete}
        >
          {t("approval.stock_and_domestic.enter_details")}
        </StepButton>
      ),
      panel: <EnterDetails />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.stock_and_domestic.info.ledgered_at")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={info.effectiveDate.body} />}
        >
          {t("date.effective.set")}
        </StepButton>
      ),
      panel: (
        <EffectiveDateForm
          level1ResourceId={details.level1Resource.id}
          showHeadAndFooter
          onDateChange={(date) => {
            setDetails((prev) => ({
              ...prev,
              effectiveDate: date,
            }));
          }}
          effectiveDate={details.effectiveDate}
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <AdministrativeApprovalEffectiveDateInformation
          workflowTitle={t("date.effective.info_panel_by_trade.stock_domestic")}
        />
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.stock_and_domestic.info.evidence")}</p>
            <p>{t("approval.stock_and_domestic.info.application_form")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={5} currentStep={currentStep} isOnChain={isComplete}>
          {t("approval.stock_and_domestic.confirm_transfer")}
        </StepButton>
      ),
      panel: <ConfirmCreateStockAndDomestic onSubmit={handleSubmit} />,
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? [
                  t("approval.stock_and_domestic.info.success", {
                    extractionRightName: details.extractionRight.name,
                    subscriberName: details.subscriber.name,
                  }),
                ]
              : [t("approval.stock_and_domestic.info.confirm")]
          }
        >
          <div className="space-y-6">
            {isComplete ? (
              t("approval.stock_and_domestic.info.end_workflow")
            ) : (
              <>
                <p>{t("approval.stock_and_domestic.info.ledgering")}</p>
                <p>{t("common.info.changes")}</p>
              </>
            )}
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default CreateStockAndDomesticWorkflow;
