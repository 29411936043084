import Select, { ActionMeta } from "react-select";
import { noop } from "lodash";
import ErrorMessage from "@components/form/ErrorMessage";
import { useQuery } from "@tanstack/react-query";
import { getEventGroups } from "@services/eventTransactions";

type OverrideProps<T, TOverridden> = Omit<T, keyof TOverridden> & TOverridden;

type Option = {
  label: string;
};

type SelectEventGroupProps = {
  className?: string;
  errorMessage?: string;
} & OverrideProps<
  React.ComponentProps<Select>,
  {
    value?: string;
    onChange?: (value: Option, actionMeta: ActionMeta<Option>) => void;
  }
>;

const SelectEventGroup: React.FunctionComponent<SelectEventGroupProps> = ({
  errorMessage,
  value,
  className,
  onChange = noop,
  ...props
}) => {
  const { data: eventGroupsData } = useQuery(["eventGroups"], getEventGroups);

  const getEventGroupOptions = () => {
    return eventGroupsData?.map((e: any) => ({
      label: e.name,
      value: e.id,
    }));
  };

  return (
    <>
      <Select
        options={getEventGroupOptions()}
        value={getEventGroupOptions()?.find((i: any) => i?.label === value)}
        className={className}
        onChange={onChange}
        openMenuOnFocus
        {...props}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectEventGroup;
